
@tailwind base;
@tailwind components;
@tailwind utilities;



  @font-face {
    font-family: "RadioCanada";
    src: url("assets/fonts/RadioCanada-Regular.ttf");
  }

  @font-face {
    font-family: "Montserrat";
    src: url("assets/fonts/MontserratAlternates-Light.ttf");
  }

  @font-face {
    font-family: "Pacifico";
    src: url("assets/fonts/Pacifico-Regular.ttf");
  }

  @font-face {
    font-family: "BrunoAce";
    src: url("assets/fonts/BrunoAce-Regular.ttf");
  }

  @font-face {
    font-family: "ArchitectsDaughter";
    src: url("assets/fonts/ArchitectsDaughter-Regular.ttf");
  }




